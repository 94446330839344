<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="CompassIcon"
        size="25"
      />Proposte Ferie</h1>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <justificationproposals-list-filters
        :user-filter.sync="userFilter"
        :user-options="users"
        :status-filter.sync="statusFilter"
        :status-options="statusOptions"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                class="text-nowrap"
                :to="{ name: 'apps-justificationproposals-add' }"
              >Nuova Proposta Ferie</b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refJustificationproposalsListTable"
        class="position-relative"
        :items="fetchJustificationproposals"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessuna Proposta Ferie corrispondente trovata"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.id }}</span>
          </div>
        </template>

        <template #cell(periodo)="data">
          <b-link :to="{ name: 'apps-justificationproposals-view', params: { id: data.item.id } }">
            <div class="text-nowrap">
              <feather-icon
                icon="CalendarIcon"
                class="mr-1 text-primary"
              />{{ parseDate(data.item.start_day) }} - {{ parseDate(data.item.end_day) }}
            </div>
          </b-link>
        </template>

        <template #cell(stato)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveStatusIcon(data.item.status)"
              size="18"
              class="mr-50"
              :class="resolveStatusColor(data.item.status)"
            />
            <span class="align-text-top text-capitalize">{{ resolveStatusName(data.item.status, data.item.max_days, data.item.selected_days) }}</span>
          </div>
        </template>

        <template #cell(destinatario)="data">
          <div class="text-nowrap">
            <div class="d-flex align-items-center">
              <b-avatar
                size="25"
                :src="data.item.user.avatar_file ? mediaUrl + data.item.user.avatar_file : ''"
                :text="avatarText(data.item.user.display_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.user.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }"
                class="mr-1"
              />
              <b-link :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }">
                #{{ data.item.user.anagrafico_id }} - {{ data.item.user.display_name }}
              </b-link>
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(azioni)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-justificationproposals-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Dettagli</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status == 'waiting'"
              @click="
                $bvModal.show('delete-modal')
                selectedJustificationproposal = data.item.id
              "
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Annulla</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalJustificationproposals"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="delete-modal"
        title="Cancella Proposta Ferie"
        footer-class="d-flex justify-content-end"
        body-class="p-0"
        size="sm"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Annulla Proposta Ferie
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <p class="m-2">
          Sei sicuro di voler annullare definitivamente questa Proposta Ferie?
        </p>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            size="sm"
            variant="danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="
              ok()
              deleteJustificationproposal(selectedJustificationproposal)
            "
          >
            OK
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BLink,
  BImg,
  BButton,
  BModal,
  BTooltip,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '@/views/apps/user/userStoreModule'
import { useToast } from 'vue-toastification/composition'
import useJustificationproposalsList from './useJustificationproposalsList'
import justificationproposalsStoreModule from '../justificationproposalsStoreModule'
import JustificationproposalsListFilters from './JustificationproposalsListFilters.vue'

export default {
  components: {
    JustificationproposalsListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BLink,
    BImg,
    BButton,
    BModal,
    BTooltip,
    BAvatar,

    vSelect,
  },
  setup() {
    const toast = useToast()

    const JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME = 'app-justificationproposals'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME)) {
      store.registerModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME, justificationproposalsStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME)) { store.unregisterModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME) }
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const users = ref(null)
    const _users = []
    store
      .dispatch('app-user/fetchUsers', { per_page: 1000, include_me: true })
      .then(response => {
        response.data[0].forEach((user, i) => {
          const _user = {
            label: `#${user.anagrafico_id} - ${user.display_name}`,
            value: user.id,
          }
          _users.push(_user)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    users.value = _users

    const statusOptions = [
      { label: 'In Attesa di Approvazione', value: 'waiting' },
      { label: 'Approvata', value: 'approved' },
      { label: 'Rifiutata', value: 'rejected' },
      { label: 'Annullata', value: 'deleted' },
    ]

    const {
      fetchJustificationproposals,
      tableColumns,
      perPage,
      currentPage,
      totalJustificationproposals,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refJustificationproposalsListTable,
      refetchData,
      parseDate,
      resolveTypeName,
      resolveTypeIcon,
      resolveTypeColor,
      resolveUserRoleVariant,
      resolveStatusIcon,
      resolveStatusName,
      resolveStatusColor,
      mediaUrl,
      userFilter,
      statusFilter,
    } = useJustificationproposalsList()

    return {
      fetchJustificationproposals,
      tableColumns,
      perPage,
      currentPage,
      totalJustificationproposals,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refJustificationproposalsListTable,
      refetchData,

      parseDate,
      resolveTypeName,
      resolveTypeIcon,
      resolveTypeColor,
      resolveUserRoleVariant,
      resolveStatusIcon,
      resolveStatusName,
      resolveStatusColor,

      mediaUrl,
      // Filter
      userFilter,
      users,
      statusOptions,
      statusFilter,
      avatarText,
      selectedJustificationproposal: null,
    }
  },
  methods: {
    deleteJustificationproposal(id) {
      store
        .dispatch('app-justificationproposals/deleteJustificationproposal', { id })
        .then(() => {
          this.selectedJustificationproposal = null
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Proposta Ferie #${id} eliminata con successo`,
              icon: 'CompassIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
