<template>
  <b-card-body>
    <b-row>
      <b-col cols="12" md="6" lg="4" class="mb-1">
        <label>Utente</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="userFilter"
          :options="userOptions"
          class="w-100"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:userFilter', val)"
        />
      </b-col>
      <b-col cols="12" md="6" lg="4" class="mb-1">
        <label>Stato</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="statusFilter"
          :options="statusOptions"
          class="w-100"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:statusFilter', val)"
        />
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    userFilter: {
      type: [Number, null],
      default: null,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
